import { h } from 'preact';
import classNames from 'classnames';
import styles from './Product.less';

export const Product = ({
  title,
  images,
  description,
  info,
  infoLayout = 'horizontal',
}) => (
  <div className={styles.container}>
    <h2 className={styles.title}>{title}</h2>
    <div className={styles.product}>
      <div className={styles.image}>
        {!!images && images.map(item => (
          <img key={item.name} src={item.src} alt={item.alt} style={item.style} />
        ))}
      </div>
      <div className={styles.description}>
        {description}
        {!!info && (
          <div className={classNames(styles.info, infoLayout === 'vertical' && styles.vertical)}>
            {!!info && info.map((item, i) => (
              <div key={i} className={styles.column}>
                <p className={styles.label}>{item.label}</p>
                <ul>
                  {!!item.rows.length && item.rows.map((row, i) => (
                    <li key={i}>{row}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  </div>
);
