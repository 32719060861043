import { h } from 'preact';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@components/common';
import * as paths from '@helpers/path-helpers';
import styles from './Header.less';

const BRAND_IMG_PATH = '/assets/brand.svg';
const BRAND_IMG_ALT = 'Логотип Алюмакс';

const PRODUCTION_PATHS = [
  paths.aluminiumPath(),
  paths.roborexPath(),
  paths.vormannPath(),
  paths.petrotechPath(),
  paths.setePath(),
];

const isNavActive = nav => {
  if (typeof window !== 'undefined') {
    if (nav instanceof Array) return !!nav.find(item => window.location.pathname === item);

    return window.location.pathname === nav;
  }

  return undefined;
};

const Link = ({ path, className, navActivePath, children }) => {
  const _className = classNames(className, {
    [styles.active]: navActivePath !== false && isNavActive(navActivePath || path),
  });

  return (
    <a href={path} className={_className}>
      {children}
    </a>
  );
};

const ProductionDropdown = () => (
  <div className={styles.dropdown}>
    <Link path={paths.vormannPath()} navActivePath={false}>
      Кронштейны и консоли VORMANN (Германия)
    </Link>
    <Link path={paths.roborexPath()} navActivePath={false}>
      Кронштейны и механизмы РОБОРЕКС (Россия)
    </Link>
    <Link path={paths.petrotechPath()} navActivePath={false}>
      Кронштейны «ПЕТРОТЕХ» (Россия)
    </Link>
    <Link path={paths.setePath()} navActivePath={false}>
      Кронштейны «SETE» (Россия)
    </Link>
    <Link path={paths.aluminiumPath()} navActivePath={false}>
      Алюминиевые конструкции PROVEDAL
    </Link>
  </div>
);

export const Header = () => (
  <header className={styles.container}>
    <span className={styles.brand_image}>
      <img src={BRAND_IMG_PATH} alt={BRAND_IMG_ALT} />
    </span>
    <nav className={styles.navigation}>
      <Link path={paths.rootPath()}>Главная</Link>
      <Link
        path={paths.roborexPath()}
        className={styles.hoverable}
        navActivePath={PRODUCTION_PATHS}
      >
        Продукция
        <FontAwesomeIcon icon="caret-down" />
        <ProductionDropdown />
      </Link>
      <Link path={paths.paymentPath()}>Оплата и доставка</Link>
      <Link path={paths.contactsPath()}>Контакты</Link>
    </nav>
    <span className={styles.contacts}>
      <a href="tel:+375173971555">тел.: (017)-397-15-55</a>
      <a href="mailto:info@alumaks.by">email: info@alumaks.by</a>
    </span>
  </header>
);
