import { h } from 'preact';
import * as paths from '@helpers/path-helpers';
import { getCurrentYear } from '@helpers/date-helpers';
import styles from './Footer.less';

const BRAND_IMG_PATH = '/assets/brand-footer.svg';
const BRAND_IMG_ALT = 'Логотип Алюмакс';

export const Footer = () => (
  <div className={styles.container}>
    <span className={styles.brand_image}>
      <img src={BRAND_IMG_PATH} alt={BRAND_IMG_ALT} />
    </span>
    <nav className={styles.navigation}>
      <a href={paths.rootPath()}>Главная</a>
      <a href={paths.roborexPath()}>Продукция</a>
      <a href={paths.paymentPath()}>Оплата и доставка</a>
      <a href={paths.contactsPath()}>Контакты</a>
    </nav>
    <span className={styles.copyright}>
      <div>{`© 2003-${getCurrentYear()}`}</div>
      <div>УНП 190436531</div>
    </span>
  </div>
);
