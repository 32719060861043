import { h } from 'preact';

export const YandexMap = () => {
  if (typeof window === 'undefined') return null;

  return (
    <script
      type="text/javascript"
      charSet="utf-8"
      async
      src="https://api-maps.yandex.ru/services/constructor/1.0/js/?um=constructor%3Ab019ed38a0c037107cf19322752f02adffa79493d58bfd79b956f1d752525c47&amp;width=100%25&amp;height=500&amp;lang=ru_RU&amp;scroll=false"
    />
  );
};
