import { h } from 'preact';
import { FontAwesomeIcon } from '@components/common';
import { Product } from '@components/product/Product';
import { contactsPath } from '@helpers/path-helpers';
import styles from './Petrotech.less';

const IMAGES_PATH = '/assets/media/holders/petrotech';

const PETROTECH_VIDEO_LINK = '/assets/media/manuals/Manual-Petrotech.mp4';

const IMAGES = {
  PETROTECH: [
    {
      name: 'petrotech-holder',
      src: `${IMAGES_PATH}/petrotech-holder.jpeg`,
      alt: 'Кронштейн «Петротех»',
    },
  ],
};

const PRICE_LIST_LINK = 'https://tinyurl.com/petrotech-holders';

const PRICE_LIST = {
  label: 'Цена:',
  rows: [
    <a
      className={styles.price_list}
      href={PRICE_LIST_LINK}
      target="_blank"
      rel="noreferrer"
    >
      Открыть прайс
      <FontAwesomeIcon icon="file-excel" />
    </a>,
  ],
  width: 165,
};

export const Petrotech = () => (
  <div className={styles.container}>
    <Product
      title={<span>Складной кронштейн <strong>«ПЕТРОТЕХ»</strong></span>}
      images={IMAGES.PETROTECH}
      description={
        <div>
          <p>
            Складной кронштейн «ПЕТРОТЕХ» для крепления подвесных полок и столиков изготовлен
            из П-образного металлического профиля, рассчитанного на большие нагрузки. Толщина
            профиля, из которого сделан кронштейн – 1,5 мм. Общая нагрузка - до 80 килограмм
            на пару кронштейнов. Благодаря оптимизированной складной конструкции, кронштейн
            позволяет, при необходимости, опустить полку или рабочую поверхность, при этом
            исключает самопроизвольное складывание. Консоль удобно использовать для обустройства
            мастерских, гаражей, коридоров, балконов и любых других помещений с ограниченным
            пространством.
          </p>
          <p>
            В складных кронштейнах «ПЕТРОТЕХ» отсутствуют нажимные клавиши, при помощи которых
            столешница складывается. Фиксация столешницы в горизонтальном положении осуществляется
            при помощи специальных пазов в профиле. Чтобы опустить столешницу вниз – нужно двумя
            руками приподнять столешницу вверх, профиль выйдет из зацепления в пазе, и столешница
            может быть опущена в вертикальное положение вдоль стены. Такое упрощение конструкции,
            как отсутствие нажимных клавиш, существенно снизило себестоимость изготовления
            кронштейнов, что сделало складные кронштейны «ПЕТРОТЕХ» одними из самых доступных на
            рынке.
          </p>
          <p>
            Кронштейн складной «ПЕТРОТЕХ» - незаменимое изделие для крепления полок к вертикальным
            поверхностям в условиях экономии пространства и объема.
          </p>
          <p>
            МАТЕРИАЛ: сталь холоднокатаная 08ПС по ГОСТ 16523-97, 19904-90 изготовлено по
            ТУ 5690-001-23091169-2001
          </p>
          <p>
            В линейке складных кронштейнов «ПЕТРОТЕХ» два типа размеров кронштейна от стены –
            280 мм и 380 мм. И два цвета покрытия – глянцевый белый и темно-коричневый антик.
            Вес пары кронштейнов – 0,7-0,8 кг. Крепежа в комплекте нет. Крепежный комплект
            подбирается самостоятельно под материал стены.
          </p>
          <p>
            <img
              className={styles.other_img}
              src={`${IMAGES_PATH}/petrotech-holder-2.jpeg`}
              alt="Кронштейн «Петротех»"
            />
            <img
              className={styles.other_img}
              src={`${IMAGES_PATH}/petrotech-holder-3.jpeg`}
              alt="Кронштейн «Петротех» со столешницей"
            />
            <img
              className={styles.other_img}
              src={`${IMAGES_PATH}/petrotech-holder-4.jpeg`}
              alt="Разложенный кронштейн «Петротех» со столешницей"
            />
            <img
              className={styles.other_img}
              src={`${IMAGES_PATH}/petrotech-holder-5.jpeg`}
              alt="Размерные обозначения кронштейна «Петротех»"
            />
          </p>
        </div>
      }
      info={[
        {
          label: 'Цвет:',
          rows: [
            <span>Белый, антик</span>,
            <span>Белый, антик</span>,
          ],
          width: 180,
        },
        { label: 'Принцип работы:',
          rows: [
            <a className={styles.video} href={PETROTECH_VIDEO_LINK} target="_blank" rel="noreferrer">
              Видео
              <FontAwesomeIcon icon="file-video" />
            </a>,
          ],
          width: 140,
        },
        {
          label: 'Размеры (AxBxC), мм.:',
          rows: [
            <span>280	x 200	x 22</span>,
            <span>380 x	200 x	22</span>,
          ],
          width: 190,
        },
        PRICE_LIST,
      ]}
    />
    <h4 style={{ textAlign: 'center', marginBottom: 30 }}>
      По стоимости и наличию обращайтесь в офис компании по адресам,
      указанным в разделе <a href={contactsPath()}>"Контакты"</a>
    </h4>
  </div>
);
