import { h } from 'preact';
import { Product } from '@components/product/Product';
import styles from './Aluminium.less';

const IMAGES_PATH = '/assets/media/aluminium';

const IMAGES = {
  BALCONIES: [
    {
      name: 'production-windows',
      src: `${IMAGES_PATH}/production-windows.jpg`,
      alt: 'Алюминиевые раздвижные балконные рамы системы «Provedal»',
    },
  ],
  VERANDS: [
    {
      name: 'production-verands',
      src: `${IMAGES_PATH}/production-verands.png`,
      alt: 'Остекление веранд и террас при помощи двух систем: «Слайдинг-60» и «Слайдинг-90»',
    },
  ],
};

export const Aluminium = () => (
  <div className={styles.container}>
    <div className={styles.title}>
      <p>
        Предприятие «АЛЮМАКС» изготавливает алюминиевые раздвижные конструкции по заказам
        строительно-монтажных организаций и индивидуальных предпринимателей.
      </p>
      <p>
        <strong>Мы не производим монтаж алюминиевых конструкций!</strong>
      </p>
    </div>
    <Product
      title={<span>Алюминиевые раздвижные балконные рамы</span>}
      images={IMAGES.BALCONIES}
      description={
        <div>
          <p>
            Система "Provedal" - лучшая, на наш взгляд, раздвижная конструкция из
            представленных на белорусском рынке. Конструкция состоит из коробки,
            выполненной заодно с направляющими, и створок на роликах. Профиль достаточно
            узкий, что значительно увеличивает световой проем. Ролики на подшипниках
            обеспечат плавный ход створок на весь срок службы. Балконные рамы остекляются
            прозрачным стеклом 4 мм. Стекло в раме установлено в U-образном резиновом
            уплотнителе. Количество створок определяется размерами объекта и пожеланиями
            заказчика. В одной раме возможно выполнение от 2-х до 7-ми створок.
          </p>
          <p>
            Профиль поставляется с завода в двух цветовых исполнениях: белом (RAL 9016) и
            коричневом (RAL 8017).
          </p>
          <p>
            Мы можем дополнительно укомплектовать раму верхним и нижним отливом,
            подоконником, противомоскитной сеткой.
          </p>
        </div>
      }
    />
    <Product
      title={<span>Остекление веранд, террас и беседок</span>}
      images={IMAGES.VERANDS}
      description={
        <div>
          <p>
            На сегодняшний день остекление веранд и террас с применением алюминиевых
            конструкций пользуется огромной популярностью. Такое остекление обеспечивает
            надёжную защиту от ветра и осадков, посторонних шумов и пыли и гарантирует
            комфортное использование пространства в любое время года.
          </p>
          <p>
            Мы предлагаем "холодное" остекление веранд и террас при помощи двух похожих
            систем: Слайдинг-60 и Слайдинг-90. Цифры обозначают ширину коробки в мм.
          </p>
          <p>
            Слайдинг-60 - это аналог раздвижной системы "Provedal", которая применяется
            для холодного остекления балконов и лоджий. Створки и коробка изготавливаются
            из такого же профиля. Но из-за большей высоты створок, мы рекомендуем в каждой
            створке делать горизонтальный импост, который придаст створке дополнительную
            жёсткость и безопасность при случайном повреждении стекла. Количество створок
            в коробке может быть от 2-х до 7-ми. Максимальное открывание в системе
            Слайдинг-60 - 50%!
          </p>
          <p>
            Слайдинг-90 создана на основе системы Слайдинг-60. Створки изготавливаются из
            такого же профиля, что и в системе Слайдинг-60. Основное отличие - коробка в
            Слайдинг-90 имеет три дорожки, по которым катаются створки. Поэтому и ширина
            этой коробки не 60, а 90 мм. Количество створок в коробке системы Слайдинг-90
            может быть либо 3, либо 6. Максимальное открывание - 66%!
          </p>
        </div>
      }
    />
  </div>
);
