import { h } from 'preact';
import { Carousel } from '@components/carousel/Carousel';
import { ContentBlock } from '@components/contentBlock/ContentBlock';
import styles from './Root.less';

const ASSETS_PATH = '/assets';
const IMAGES_PATH = `${ASSETS_PATH}/media`;
const CAROUSEL_DURATION = 10000;

const slides = [
  {
    title: 'Кронштейны для откидного стола "РОБОРЕКС"',
    description: 'Продажа кронштейнов для откидного стола "РОБОРЕКС" в Минске и Беларуси с доставкой. В наличии обширная коллекция различных кронштейнов для огромного спектра задач: от откидной полки для цветов до откидного верстака в гараже!',
    Component: props => (
      <img
        src={`${IMAGES_PATH}/holders/roborex/roborex-main-image.jpg`}
        alt="Кронштейны для откидного стола РОБОРЕКС"
        {...props}
      />
    ),
  },
  {
    title: 'Кронштейны для откидного стола и консоли "VORMANN"',
    description: 'Продажа консолей, полкодержателей и кронштейнов "VORMANN" с доставкой по Минску и Беларуси. Данные механизмы немецкого качества способны выдержать впечатляющие нагрузки, что оценят покупатели, имеющие дело с тяжёлыми вещами в работе и быту!',
    Component: props => (
      <img
        src={`${IMAGES_PATH}/holders/vormann/vormann-main-image.jpg`}
        alt="Кронштейны для откидного стола и консоли VORMANN"
        {...props}
      />
    ),
  },
];

export const FeatureBadge = ({ icon, title, children }) => {
  return (
    <div className={styles.featureBadge}>
      <div className={styles.icon}>
        <img src={icon} alt="" />
      </div>
      <div className={styles.title}>
        {title}
      </div>
      <div className={styles.divider} />
      <div className={styles.content}>
        {children}
      </div>
    </div>
  );
};

export const FeaturesBadgesList = () => {
  return (
    <div className={styles.featureBadgesContainer}>
      <FeatureBadge
        icon={`${ASSETS_PATH}/experience.svg`}
        title="Опыт"
      >
        <p>
          <strong>АЛЮМАКС</strong> - компания с многолетним опытом. На протяжении{' '}
          <strong>16-ти лет</strong> мы оправдываем доверие своих партнёров.
          Присоединяйтесь к числу постоянных и довольных клиентов!
        </p>
      </FeatureBadge>
      <FeatureBadge
        icon={`${ASSETS_PATH}/help.svg`}
        title="Поддержка"
      >
        <p>
          <strong>АЛЮМАКС</strong> обеспечивает поддержку и помощь{' '}
          <strong>в любое время</strong>. Позвоните или отправьте сообщение в Viber,
          WhatsApp и мы <strong>в сжатые сроки</strong> поможем вам!
        </p>
      </FeatureBadge>
      <FeatureBadge
        icon={`${ASSETS_PATH}/technology.svg`}
        title="Инновации"
      >
        <p>
          <strong>АЛЮМАКС</strong> постоянно находится в поиске{' '}
          <strong>инновационных</strong> продуктов с новыми и полезными свойствами
        </p>
      </FeatureBadge>
    </div>
  );
};

export const Root = () => (
  <div className={styles.container}>
    <Carousel slides={slides} duration={CAROUSEL_DURATION} />
    <div className={styles.contentBlocksContainer}>
      <ContentBlock title="О компании">
        <p>
          Предприятие "АЛЮМАКС" было основано в Минске в 2003 году.
          Основным направлением деятельности предприятия является изготовление
          алюминиевых конструкций по заказам предприятий-дилеров и строительно-монтажных
          организаций, индивидуальных предпринимателей.
        </p>
        <p>
          С 2017 года предприятие "АЛЮМАКС" начало поставлять на белорусский рынок
          продукцию торговых марок Роборекс (РФ) и VORMANN (Германия). Это складные
          кронштейны и механизмы, консоли. Продукция этих торговых марок зарекомендовала
          себя как качественная, оригинальная по исполнению и демократичная по цене.
        </p>
        <FeaturesBadgesList />
      </ContentBlock>
      <ContentBlock title="Благодарности и рекомендации">
        <div className={styles.references}>
          <a href={`${IMAGES_PATH}/ref_1.jpg`} native>
            <img
              src={`${IMAGES_PATH}/ref_1.jpg`}
              alt="Рекомендательное письмо МинскРемстрой"
            />
          </a>
          <a href={`${IMAGES_PATH}/ref_2.jpg`} native>
            <img
              src={`${IMAGES_PATH}/ref_2.jpg`}
              alt="Рекомендательное письмо СолигорскПромСтрой"
            />
          </a>
          <a href={`${IMAGES_PATH}/ref_3.jpg`} native>
            <img
              src={`${IMAGES_PATH}/ref_3.jpg`}
              alt="Свидетельство попечительства общества милосердия инвалидов ВОВ"
            />
          </a>
          <a href={`${IMAGES_PATH}/ref_4.jpg`} native>
            <img
              src={`${IMAGES_PATH}/ref_4.jpg`}
              alt="Грамота приходского храма Софии Слуцкой"
            />
          </a>
          <a href={`${IMAGES_PATH}/ref_5.jpg`} native>
            <img
              src={`${IMAGES_PATH}/ref_5.jpg`}
              alt={'Диплом участия в акции "Вы в Наших Сердцах"'}
            />
          </a>
        </div>
      </ContentBlock>
    </div>
  </div>
);
