import { h } from 'preact';
import { SpinnerLoader } from '@components/common/SpinnerLoader/SpinnerLoader';
import { YandexMap } from '@components/yandexMap/YandexMap';
import styles from './Contacts.less';

const EMAIL_PATH = 'mailto:info@alumaks.by';

export const Contacts = () => (
  <div className={styles.container}>
    <h1>Вы можете связаться с нами следующими способами:</h1>
    <p>
      <ul>
        <li>По городскому телефону: <strong>(017)-397-15-55</strong>;</li>
        <li>По мобильному телефону: <strong>(029)-683-79-63 (VELCOM)</strong>;</li>
        <li>Telegram: <strong>(029)-683-79-63</strong>;</li>
        <li>По электронной почте:{' '}
          <a href={EMAIL_PATH} target="_blank"><strong>info@alumaks.by</strong></a>;
        </li>
        <li>По адресу:
          <strong> г. Минск, ул. Харьковская, д. 3А, корп. 8, (цех ЖБИ), заезд с ул. Домашевская </strong>
          (пн-пт 09:00 - 17:00)
        </li>
      </ul>
    </p>
    <div className={styles.map}>
      <SpinnerLoader size={150} />
      <YandexMap />
    </div>
    <h1>Реквизиты для оплаты:</h1>
    <p>
      ЧПТУП "АЛЮМАКС"  УНП 190436531<br />
      р/с BY32BLBB30120190436531001001 в филиале № 500 ГОПЕРУ<br />
      ОАО "Белинвестбанк", код BLBBBY2X<br />
      г.Минск, пр-т Машерова, 29
    </p>
  </div>
);
