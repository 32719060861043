import { h } from 'preact';
import { FontAwesomeIcon } from '@components/common';
import { Product } from '@components/product/Product';
import { contactsPath } from '@helpers/path-helpers';
import styles from './Roborex.less';

const IMAGES_PATH = '/assets/media/holders/roborex';
const BLUEPRINTS_PATH = '/assets/media/blueprints';
const MANUALS_PATH = '/assets/media/manuals';

const IMAGES = {
  ETJUD: [
    {
      name: 'roborex-etjud',
      src: `${IMAGES_PATH}/roborex-etjud.jpg`,
      alt: 'Кронштейн «Этюд» для откидной полочки или откидного столика',
    },
  ],
  ETJUD_M: [
    {
      name: 'roborex-etjud_m',
      src: `${IMAGES_PATH}/roborex-etjud_m.jpg`,
      alt: 'Механизм для откидного стола или полки «Этюд М»',
    },
  ],
  PROTEY: [
    {
      name: 'roborex-protey',
      src: `${IMAGES_PATH}/roborex-protey.jpg`,
      alt: 'Кронштейн «Протей» для откидного стола или полки',
    },
  ],
  PROTEY_COMFORT: [
    {
      name: 'roborex-protey-comfort',
      src: `${IMAGES_PATH}/roborex-protey-comfort.jpg`,
      alt: 'Кронштейн «Протей Комфорт» для откидного стола или полки',
    },
  ],
  MASTER: [
    {
      name: 'roborex-master',
      src: `${IMAGES_PATH}/roborex-master.jpg`,
      alt: 'Механизм откидного стола «Мастер»',
    },
  ],
  UJUT: [
    {
      name: 'roborex-ujut',
      src: `${IMAGES_PATH}/roborex-ujut.jpg`,
      alt: 'Механизм откидного стола «Уют»',
    },
  ],
  EXPERT: [
    {
      name: 'roborex-expert',
      src: `${IMAGES_PATH}/roborex-expert.jpg`,
      alt: 'Откидной пристенный стол (верстак) «Эксперт»',
    },
  ],
  EXPERT_COMFORT: [
    {
      name: 'roborex-expert-comfort',
      src: `${IMAGES_PATH}/roborex-expert-comfort.jpg`,
      alt: 'Откидной пристенный стол (верстак) «Эксперт Комфорт»',
    },
  ],
};

const PRICE_LIST_LINK = 'https://tinyurl.com/alumaks-holders';

const ETJUD_VIDEO_LINK = 'https://www.youtube.com/watch?v=SGKKP4d-6SY';
const PROTEY_VIDEO_LINK = 'https://www.youtube.com/watch?v=JVF8ATbJl6k';
const MASTER_VIDEO_LINK = 'https://www.youtube.com/watch?v=QxovOQd4Q7k';
const EXPERT_VIDEO_LINK_1 = 'https://www.youtube.com/watch?v=JsFAnmGQEJY';
const EXPERT_VIDEO_LINK_2 = 'https://www.youtube.com/watch?v=liH-iqn1grM';

export const Roborex = () => (
  <div className={styles.container}>
    <Product
      title={<span>Кронштейны <strong>«Этюд»</strong> для откидной полочки или откидного столика</span>}
      images={IMAGES.ETJUD}
      description={
        <div>
          <p>
            На такие складные кронштейны можно спокойно ставить бытовую технику на кухне,
            не очень тяжелые станки в мастерской или инструменты в гараже.
            Используя пару кронштейнов «Этюд» и подходящую столешницу из ЛДСП, можно сделать
            откидной столик на балкон своими руками. Толщина стального профиля, из которого сделаны
            эти кронштейны - 1,5 мм. Поэтому, в отличие от похожих китайских кронштейнов,
            выдерживающих вес не более 35 кг на пару, грузоподъемность двух настенных кронштейнов
            «Этюд» — 100 кг распределенного веса.
          </p>
          <p>
            Чтобы сложить столешницу, закрепленную на таких кронштейнах, нужно приподнять ее и
            нажать на клавиши на каждом из кронштейнов, затем сопроводить  столешницу вниз.
            Чтобы разложить — просто потянуть за край столешницы до ее горизонтального уровня,
            стопоры зафиксируют ее в таком положении.
          </p>
          <p>
            Нажимные клавиши в кронштейнах «Этюд» расположены у самого края кронштейна. Это значит,
            что на готовом столе они будут находиться не дальше глубины вытянутой ладони от
            края столешницы. Такое расположение позволяет легко находить нажимные клавиши под
            столешницей и удобно складывать ее вниз. В похожих кронштейнах, где нажимная клавиша
            расположена ближе к середине кронштейна, при складывании столешницы вниз приходится
            приседать и, засунув руку под столешницу почти по локоть, искать нажимную клавишу для
            складывания столешницы.
          </p>
          <p>
            В серии «Этюд» две модели — «Этюд 300» и «Этюд 400». Модель «Этюд 300» позволяет
            использовать столешницу глубиной 300-400 мм, «Этюд 400» — соответственно 400-500 мм.
          </p>
          <p>
            В продаже кронштейны чёрного, белого и светло-серого цветов. Пара кронштейнов весит
            около 2 кг. Продаются парой.
          </p>
          <p>
            Комплект поставки: 2 кронштейна, инструкция по установке, саморезы для крепления
            столешницы к кронштейнам (4,2х13-10шт.), саморезы для крепления кронштейнов к
            деревянному основанию (Саморез DIN 571 6x40-6шт.). Если у вас основание из другого
            материала (кирпич, бетон, гипсокартон и т.п.), нужно подбирать крепежный комплект
            самостоятельно под конкретный материал.
          </p>
        </div>
      }
      info={[
        {
          label: 'Габаритные размеры:',
          rows: [
            <a href={`${BLUEPRINTS_PATH}/Blueprint-Etjude_300.pdf`} target="_blank">Этюд
              300</a>,
            <a href={`${BLUEPRINTS_PATH}/Blueprint-Etjude_400.pdf`} target="_blank">Этюд
              400</a>,
          ],
          width: 180,
        },
        {
          label: 'Принцип работы:',
          rows: [
            <a className={styles.video} href={ETJUD_VIDEO_LINK} target="_blank">
              Видео
              <FontAwesomeIcon icon="file-video" />
            </a>,
          ],
          width: 140,
        },
        {
          label: 'Цена:',
          rows: [
            <a className={styles.price_list} href={PRICE_LIST_LINK} target="_blank">
              Открыть прайс
              <FontAwesomeIcon icon="file-excel" />
            </a>,
          ],
          width: 190,
        },
      ]}
    />
    <Product
      title={
        <span>Кронштейны <strong>«Протей»</strong> для откидного стола или полки</span>}
      images={IMAGES.PROTEY}
      description={
        <div>
          <p>
            Это мощные кронштейны, способные нести большие нагрузки. Толщина стального профиля,
            из которого сделаны эти кронштейны, составляет 2 мм. Пара кронштейнов «Протей»
            выдерживает до 150 кг распределенной по столешнице нагрузки. С такими кронштейнами
            и столешницей у Вас получится отличный настенный стол, полка или сиденье в комнате,
            на балконе или в гараже, на даче. С кронштейнами «Протей» рекомендуемая ширина
            столешницы (размер вдоль стены) 900-1400мм, толщина столешницы – 26-28мм.
          </p>
          <p>
            В отличие от серии «Этюд», складывание кронштейнов в серии «Протей» происходит при
            помощи подпружиненных рычажков. Для раскладывания столешницы нужно потянуть вверх за
            ее край до фиксации в горизонтальном положении. Подпружиненные рычажки расположены у
            самого края кронштейна. Это значит, что на готовом столе они будут находиться не
            дальше глубины вытянутой ладони от края столешницы. Такое расположение позволяет легко
            находить рычажки под столешницей и удобно опускать ее вниз.
          </p>
          <p>
            В линейке «Протей» три модели: «Протей 400», «Протей 500», «Протей 600». К ним
            подходят столешницы шириной 400-500, 500- 600 и 600-700 мм соответственно.
          </p>
          <p>
            В продаже кронштейны черного, белого и светло-серого цвета. Пара кронштейнов весит
            около 3,5 кг. Кронштейны продаются парой.
          </p>
          <p>
            Комплект поставки: 2 кронштейна, инструкция по установке, саморезы для крепления
            столешницы к кронштейнам (4,2х13-10шт.), распорные анкеры для крепления в бетон
            (8х85-6 шт). Если у вас основание из другого материала (полый кирпич, гипсокартон и
            т.п.), нужно подбирать крепежный комплект самостоятельно под конкретный материал.
          </p>
        </div>
      }
      info={[
        {
          label: 'Габаритные размеры:',
          rows: [
            <a href={`${BLUEPRINTS_PATH}/Blueprint-Protey_400.pdf`} target="_blank">Протей
              400</a>,
            <a href={`${BLUEPRINTS_PATH}/Blueprint-Protey_500.pdf`} target="_blank">Протей
              500</a>,
            <a href={`${BLUEPRINTS_PATH}/Blueprint-Protey_600.pdf`} target="_blank">Протей
              600</a>,
          ],
          width: 180,
        },
        {
          label: 'Принцип работы:',
          rows: [
            <a className={styles.video} href={PROTEY_VIDEO_LINK} target="_blank">
              Видео
              <FontAwesomeIcon icon="file-video" />
            </a>,
          ],
          width: 140,
        },
        {
          label: 'Цена:',
          rows: [
            <a className={styles.price_list} href={PRICE_LIST_LINK} target="_blank">
              Открыть прайс
              <FontAwesomeIcon icon="file-excel" />
            </a>,
          ],
          width: 190,
        },
      ]}
    />
    <Product
      title={
        <span>
          Кронштейны <strong>«Протей Комфорт»</strong> для откидного стола или полки
        </span>}
      images={IMAGES.PROTEY_COMFORT}
      description={
        <div>
          <p>
            Отличаются от кронштейнов «Протей» наличием газовых лифтов по 120N для компенсации
            веса столешницы. При подъеме столешницы они ее подталкивают вверх, при опускании -
            придерживают. В результате получается плавный ход механизма. Особенно актуально при
            большом весе столешницы. Остальные характеристики (цвет, размеры, выдерживаемая
            нагрузка и т.п.) такие же, как у просто «Протей».
          </p>
        </div>
      }
      info={[
        {
          label: 'Габаритные размеры:',
          rows: [
            <a href={`${BLUEPRINTS_PATH}/Blueprint-Protey_400.pdf`} target="_blank">
              Протей Комфорт 400
            </a>,
            <a href={`${BLUEPRINTS_PATH}/Blueprint-Protey_500.pdf`} target="_blank">
              Протей Комфорт 500
            </a>,
            <a href={`${BLUEPRINTS_PATH}/Blueprint-Protey_600.pdf`} target="_blank">
              Протей Комфорт 600
            </a>,
          ],
          width: 180,
        },
        {
          label: 'Принцип работы:',
          rows: [
            <a className={styles.video} href={PROTEY_VIDEO_LINK} target="_blank">
              Видео
              <FontAwesomeIcon icon="file-video" />
            </a>,
          ],
          width: 140,
        },
        {
          label: 'Цена:',
          rows: [
            <a className={styles.price_list} href={PRICE_LIST_LINK} target="_blank">
              Открыть прайс
              <FontAwesomeIcon icon="file-excel" />
            </a>,
          ],
          width: 190,
        },
      ]}
    />
    <Product
      title={<span>Механизм для откидного пристенного стола <strong>«Мастер»</strong></span>}
      images={IMAGES.MASTER}
      description={
        <div>
          <p>
            Откидной механизм «Мастер» - это готовое решение для тех, кто хочет сделать
            небольшой складной стол своими руками. Изготовление такого откидного стола с
            креплением к стене не займет много времени. Такой столик пригодится там, где
            недостаточно свободного места для стационарного стола, или в добавок к нему.
            На кухне на таком столике можно нарезать продукты, ставить кухонную посуду и
            сервировать блюда. В детской комнате на нем можно писать, рисовать или что-то
            мастерить. На балконе столик пригодится, чтобы поставить пепельницу и чашечку
            кофе. А когда такой стол не нужен – он легко складывается параллельно стене.
          </p>
          <p>
            Складывается и раскладывается механизм «Мастер» движением одной руки, нажимая
            на рычажок. При этом механизм очень надежный. Толщина стального профиля, из
            которого он сделан - 2 мм, допустимая нагрузка - до 150 кг. Необходимо только
            предусмотреть надежное крепление стола к стене!
          </p>
          <p>
            Серия «Мастер» представлена шестью моделями. Ниже указан минимальный размер
            столешницы для конкретной модели механизма откидного стола (первый размер
            столешницы вдоль стены), мм:
            <br /> — «Мастер 400» - 500х400
            <br /> — «Мастер 500» - 600х500
            <br /> — «Мастер 600» - 700х600
            <br /> — «Мастер 600 узкий» - 400х600
            <br /> — «Мастер 700» - 700х700
            <br /> — «Мастер 700 узкий» - 500х700<br />
            Механизм окрашен в светло-серый цвет. Вес — 3-4,5кг.
          </p>
        </div>
      }
      info={[
        {
          label: 'Габаритные размеры:',
          rows: [
            <a href={`${BLUEPRINTS_PATH}/Blueprint-Master_400.pdf`} target="_blank">Мастер
              400</a>,
          ],
          width: 180,
        },
        {
          label: 'Принцип работы:',
          rows: [
            <a className={styles.video} href={MASTER_VIDEO_LINK} target="_blank">
              Видео
              <FontAwesomeIcon icon="file-video" />
            </a>,
          ],
          width: 140,
        },
        {
          label: 'Цена:',
          rows: [
            <a className={styles.price_list} href={PRICE_LIST_LINK} target="_blank">
              Открыть прайс
              <FontAwesomeIcon icon="file-excel" />
            </a>,
          ],
          width: 190,
        },
      ]}
    />
    <Product
      title={<span>Механизм откидного стола <strong>«Уют»</strong></span>}
      images={IMAGES.UJUT}
      description={
        <div>
          <p>
            Механизм для складного стола «Уют» состоит из двух частей – одна часть держит
            пристенную наподвижную полочку шириной 100мм, а вторая часть служит опорой для
            складной столешницы столика.
          </p>
          <p>
            Такой откидной столик можно установить в детских или спальных комнатах, или на кухне. В
            отличие от модели «Мастер», где складывается полностью вся столешница, на неподвижной
            полочке Уюта можно оставлять некоторые некрупные предметы (настольную лампу, вазу,
            калькулятор, ежедневник и т.п.). Еще одним плюсом модели «Уют» является то, что
            неподвижная полочка прикрывает щель, образующуюся между стеной и опущенной вниз
            столешницей.
          </p>
          <p>
            Подстолье поворотной столешницы оснащено ручкой: потянуть за нее – и конструкция
            сложена.
          </p>
          <p>
            — Допустимая распределенная нагрузка на стол «Уют»: до 60 кг
            <br /> — Вес механизма: 5 кг
            <br /> — Детали механизма изготовлены из стального профиля толщиной 1,5 мм.
            Окрашены порошковой краской.
            <br /> — Цвет: светло-серый.
            <br /> — Ширина полочки во всех моделях «Уют» одинаковая - 100 мм.
          </p>
          <p>
            Диапазон возможных размеров поворотной столешницы:
            <br /> — Механизм Уют 500/500 - ширина поворотной столешницы (вдоль стены): 500-600мм,
            длина поворотной столешницы (от полочки): 380-500мм.
            <br /> — Механизм Уют 600/600 - ширина поворотной столешницы (вдоль стены): 600-700мм,
            длина поворотной столешницы (от полочки): 480-600мм.
            <br /> — Механизм Уют 700/700 - ширина поворотной столешницы (вдоль стены): 700-800мм,
            длина поворотной столешницы (от полочки): 580-700мм.
          </p>
          <p>
            <strong>
              Требуется самостоятельная сборка. Ознакомьтесь с инструкцией по
              установке перед покупкой.
            </strong>
          </p>
          <p>
            <img
              className={styles.other_img}
              src={`${IMAGES_PATH}/roborex-ujut-2.jpg`}
              alt="Фото кронштейна Уют"
            />
            <img
              className={styles.other_img}
              src={`${IMAGES_PATH}/roborex-ujut-3.jpg`}
              alt="Фото сложенного кронштейна Уют"
            />
          </p>
        </div>
      }
      info={[
        {
          label: 'Габаритные размеры:',
          rows: [
            <a href={`${BLUEPRINTS_PATH}/Blueprint-Ujut_500.pdf`} target="_blank">Уют 500/500</a>,
            <a href={`${BLUEPRINTS_PATH}/Blueprint-Ujut_600.pdf`} target="_blank">Уют 600/600</a>,
            <a href={`${BLUEPRINTS_PATH}/Blueprint-Ujut_700.pdf`} target="_blank">Уют 700/700</a>,
          ],
          width: 180,
        },
        {
          label: 'Руководство пользователя:',
          rows: [
            <a href={`${MANUALS_PATH}/Manual-Ujut.pdf`} target="_blank">Открыть</a>,
          ],
          width: 180,
        },
        {
          label: 'Цена:',
          rows: [
            <a className={styles.price_list} href={PRICE_LIST_LINK} target="_blank">
              Открыть прайс
              <FontAwesomeIcon icon="file-excel" />
            </a>,
          ],
          width: 190,
        },
      ]}
    />
    <Product
      title={<span>Механизм для откидного пристенного стола (верстака) <strong>«Эксперт»</strong></span>}
      images={IMAGES.EXPERT}
      description={
        <div>
          <p>
            Механизм складного стола-верстака «Эксперт» позволит Вам сделать
            эргономичное и крепкое временное рабочее место в подвале частного дома,
            гараже, стол в мастерской, на балконе. В сложенном положении такой складной
            верстак вообще не будет занимать место в помещении. А в разложенном виде
            получится рабочее место размером примерно 1400х600 мм.
          </p>
          <p>
            Этот механизм имеет стальную несущую раму и способен выдерживать нагрузку
            до 250 кг! Для надежного крепления к бетонной стене в комплекте идут
            металлические анкеры диаметром 8 мм. На таком верстаке можно выполнять и
            продольные, и поперечные движения – строгать, пилить, стучать…
          </p>
          <p>
            Для того, чтобы разложить стол-верстак, нужно поднять столешницу вверх до
            фиксации, и она надежно займет горизонтальное положение. Чтобы сложить стол-
            верстак, Вам нужно будет одновременно нажать два подпружиненных рычажка.
          </p>
          <p>
            У нас Вы можете купить складной механизм и сделать складной верстак на
            балконе или в гараже своими руками.
          </p>
          <p>
            Серия «Эксперт» представлена двумя моделями: «Эксперт 500» для столешниц
            размером 1350х500 мм и «Эксперт 600» для столешниц размером 1350х600 мм.
          </p>
          <p>
            Механизм окрашен в светло-серый цвет. Вес – около 9 кг.
          </p>
        </div>
      }
      info={[
        {
          label: 'Габаритные размеры:',
          rows: [
            <a href={`${BLUEPRINTS_PATH}/Blueprint-Expert_600.pdf`}
              target="_blank"
            >Эксперт</a>,
          ],
          width: 180,
        },
        {
          label: 'Принцип работы:',
          rows: [
            <a className={styles.video} href={EXPERT_VIDEO_LINK_1} target="_blank">
              Видео 1
              <FontAwesomeIcon icon="file-video" />
            </a>,
            <a className={styles.video} href={EXPERT_VIDEO_LINK_2} target="_blank">
              Видео 2
              <FontAwesomeIcon icon="file-video" />
            </a>,
          ],
          width: 140,
        },
        {
          label: 'Цена:',
          rows: [
            <a className={styles.price_list} href={PRICE_LIST_LINK} target="_blank">
              Открыть прайс
              <FontAwesomeIcon icon="file-excel" />
            </a>,
          ],
          width: 190,
        },
      ]}
    />
    <Product
      title={
        <span>
          Механизм для откидного пристенного стола (верстака) <strong>«Эксперт Комфорт»</strong>
        </span>
      }
      images={IMAGES.EXPERT_COMFORT}
      description={
        <div>
          <p>
            Механизм откидного стола Эксперт, оснащенный газовыми лифтами для
            компенсации веса столешницы. Остальные характеристики (цвет, размеры,
            выдерживаемая нагрузка и т.п.) такие же.
          </p>
        </div>
      }
      info={[
        {
          label: 'Габаритные размеры:',
          rows: [
            <a href={`${BLUEPRINTS_PATH}/Blueprint-Expert_600.pdf`} target="_blank">
              Эксперт Комфорт
            </a>,
          ],
          width: 180,
        },
        {
          label: 'Принцип работы:',
          rows: [
            <a className={styles.video} href={EXPERT_VIDEO_LINK_1} target="_blank">
              Видео 1
              <FontAwesomeIcon icon="file-video" />
            </a>,
            <a className={styles.video} href={EXPERT_VIDEO_LINK_2} target="_blank">
              Видео 2
              <FontAwesomeIcon icon="file-video" />
            </a>,
          ],
          width: 140,
        },
        {
          label: 'Цена:',
          rows: [
            <a className={styles.price_list} href={PRICE_LIST_LINK} target="_blank">
              Открыть прайс
              <FontAwesomeIcon icon="file-excel" />
            </a>,
          ],
          width: 190,
        },
      ]}
    />
    <h4 style={{ textAlign: 'center', marginBottom: 30 }}>
      По стоимости и наличию обращайтесь в офис компании по адресам,
      указанным в разделе <a href={contactsPath()}>"Контакты"</a>
    </h4>
  </div>
);
