import { h } from 'preact';
import styles from './SpinnerLoader.less';

const getStyle = size => ({
  width: size,
  height: size,
  marginTop: -(size / 2),
  marginLeft: -(size / 2),
});

export const SpinnerLoader = ({ size = 100 }) => {
  if (typeof window === 'undefined') return null;

  return (
    <div className={styles.container} style={getStyle(size)} />
  );
};
