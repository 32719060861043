import { h } from 'preact';
import { FontAwesomeIcon } from '@components/common';
import { Product } from '@components/product/Product';
import styles from './Vormann.less';

const IMAGES_PATH = '/assets/media/holders/vormann';

const IMAGES = {
  MULTI_LINE: [
    {
      name: 'vormann-multi-line',
      src: `${IMAGES_PATH}/vormann-multi-line.jpg`,
      alt: 'Кронштейн «Multi-Line» складной',
    },
  ],
  T_PROFIL: [
    {
      name: 'vormann-tprofil',
      src: `${IMAGES_PATH}/vormann-tprofil.jpeg`,
      alt: 'Кронштейн «T-Profil» усиленный',
    },
  ],
};

const PRICE_LIST = {
  label: 'Цена:',
  rows: [
    <a
      className={styles.price_list}
      href="https://docs.google.com/spreadsheets/d/1P5NZ6zdjW4P9sMtzw_c93SB-VgdJ6u9OyEN6ZH-GKYc/edit#gid=198364964"
      target="_blank"
    >
      Открыть прайс
      <FontAwesomeIcon icon="file-excel" />
    </a>,
  ],
  width: 165,
};

export const Vormann = () => (
  <div className={styles.container}>
    <p className={styles.title}>
      Консоли и кронштейны являются важной частью ассортимента VORMANN. Поскольку консоли
      используются в качестве функциональных и декоративных элементов, требования к их
      качеству и
      внешнему виду особенно высоки. На заводе VORMANN в Эльстерверде, Германия, консоли
      изготавливаются с использованием роботов. VORMANN применяет современные технологии
      производства для обеспечения эффективных производственных процессов и изготовления
      высококачественной продукции — с маркировкой «Made in Germany!»
    </p>
    <Product
      title={<span>Кронштейн <strong>«Multi-Line»</strong> складной</span>}
      images={IMAGES.MULTI_LINE}
      description={
        <span>
          <p>
            Кронштейн серии «Multi-Line», благодаря фиксатору положения, позволяет зафиксировать
            столешницу в трех положениях: 90°, 80°, 70°. Очень надежный кронштейн для настенного
            крепления складной полки, стола, сидения. Наклон столешницы в трех положениях позволяет
            адаптировать стол для разных нужд. Малышам на таком наклонном столе, похожем на школьную
            парту, будет удобнее учиться писать и рисовать. Студенту чертить. Если Вы хотите сделать
            многофункциональный складной стол своими руками – Вам достаточно купить
            откидной кронштейн «Multi-Line» и столешницу.
          </p>
          <p>
            Чтобы сложить кронштейн,нужно нажать на подпружиненную клавишу и
            разблокировать фиксатор. После этого столешница легко опустится до
            вертикального положения вдоль стены.
          </p>
          <p>
            Допустимая нагрузка на пару кронштейнов – 200 кг. Серия «Multi-Line»
            представлена двумя моделями разных размеров – 300х200х30 и 400х270х36 мм.
            Толщина стального оцинкованного профиля, из которого сделан кронштейн -
            1,5 мм и 2 мм соответственно. Вес пары кронштейнов – 1,25 кг и 2,53 кг.
            Каждая модель есть в двух цветовых решениях – просто цинк и белый полимер.
            Кронштейны продаются парой.
          </p>
          <p>
            <img
              className={styles.other_img}
              src={`${IMAGES_PATH}/vormann-multi-line-3.png`}
              alt="Разложенный кронштейн «Multi-Line» с примерами использования"
            />
            <img
              className={styles.other_img}
              src={`${IMAGES_PATH}/vormann-multi-line-2.png`}
              alt="Размерные обозначения кронштейна «Multi-Line» с углами раскладывания"
            />
          </p>
        </span>
      }
      info={[
        {
          label: 'Цвет:',
          rows: [ <span>Цинк, Белый</span>, <span>Цинк, Белый</span> ],
          width: 180,
        },
        { label: 'Макс. нагрузка:', rows: [ <span>200 кг на пару</span> ], width: 140 },
        {
          label: 'Размеры (AxBxC), мм.:',
          rows: [
            <span>300 x 200 x 30</span>,
            <span>400 x 270 x 36</span>,
          ],
          width: 190,
        },
        PRICE_LIST,
      ]}
    />
    <Product
      title={<span>Кронштейн <strong>«T-Profil»</strong> усиленный</span>}
      images={IMAGES.T_PROFIL}
      description={
        <span>
          <p>
            Промышленная консоль с усиленным Т-образным профилем. Защитное покрытие нанесено
            методом горячего цинкования. Предназначены для высоких нагрузок и за счет метода
            оцинковки подходят для установки не только в сухих помещениях, но и на улице.
          </p>
          <p>
            Цвет - естественный цинк.
          </p>
          <p>
            Допустимая нагрузка - 400 кг.
          </p>
          <p>
            Размер: А - 300мм, В - 400мм, С - 40мм. Толщина металла - 5мм.
          </p>
          <p>
            <img
              className={styles.other_img}
              src={`${IMAGES_PATH}/vormann-tprofil-2.jpeg`}
              alt="Применение кронштейна «T-Profil»"
            />
            <img
              className={styles.other_img}
              src={`${IMAGES_PATH}/vormann-tprofil-3.jpeg`}
              alt="Размерные обозначения кронштейна «T-Profil»"
            />
          </p>
        </span>
      }
      info={[
        {
          label: 'Цвет:',
          rows: [<span>Естественный цинк</span>],
          width: 180,
        },
        { label: 'Макс. нагрузка:', rows: [ <span>400 кг на пару</span> ], width: 140 },
        {
          label: 'Размеры (AxBxC), мм.:',
          rows: [<span>300 x 400 x 40</span>],
          width: 190,
        },
        PRICE_LIST,
      ]}
    />
  </div>
);
