import { h } from 'preact';
import { useCallback, useEffect, useState } from 'preact/hooks';
import styles from './Carousel.less';

const INITIAL_STATE = {
  activeSlide: 0,
};

export const Carousel = ({ slides, duration }) => {
  const [activeSlide, setActiveSlide] = useState(INITIAL_STATE.activeSlide);

  const showNextSlide = useCallback(() => {
    if (activeSlide >= slides.length - 1) {
      setActiveSlide(INITIAL_STATE.activeSlide);
    } else {
      setActiveSlide(activeSlide + 1);
    }
  }, [activeSlide]);

  useEffect(() => {
    if (duration) {
      const intervalId = setInterval(showNextSlide, duration);

      return () => clearInterval(intervalId);
    }
  }, [duration, showNextSlide]);

  const _slides = slides.map(slide => (
    <span
      key={slide.title}
      className={styles.slide}
      style={{ transform: `translateX(-${activeSlide * 100}%)` }}
    >
      <slide.Component />
    </span>
  ));

  return (
    <>
      <div className={styles.container}>
        <div className={styles.slidesContainer}>
          {_slides}
          <div className={styles.overlay}>
            <div className={`${styles.section} ${styles.title}`}>
              <h2>{slides[activeSlide].title}</h2>
            </div>
            <div className={`${styles.section} ${styles.description}`}>
              <p>{slides[activeSlide].description}</p>
            </div>
          </div>
        </div>
        <div className={styles.slidesButtons}>
          {slides.map((slide, i) => (
            <button
              aria-pressed={activeSlide === i}
              onClick={() => setActiveSlide(i)}
            >
              {slide.title}
            </button>
          ))}
        </div>
      </div>
    </>
  );
};
