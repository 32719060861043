import { h } from 'preact';
import Router from 'preact-router';
import Match from 'preact-router/match';
import * as paths from '@helpers/path-helpers';
import { Header } from './header/Header';
import { Footer } from './footer/Footer';
import { Root } from '../routes/root/Root';
import { Roborex } from '../routes/holders/roborex/Roborex';
import { Vormann } from '../routes/holders/vormann/Vormann';
import { Petrotech } from '../routes/holders/petrotech/Petrotech';
import { Sete } from '../routes/holders/sete/Sete';
import { Aluminium } from '../routes/aluminium/Aluminium';
import { Payment } from '../routes/payment/Payment';
import { Contacts } from '../routes/contacts/Contacts';

export const App = () => (
  <div id="app">
    <Match path={paths.rootPath()}>
      {() => <Header />}
    </Match>
    <Router>
      <Root path={paths.rootPath()} />
      <Roborex path={paths.roborexPath()} />
      <Vormann path={paths.vormannPath()} />
      <Petrotech path={paths.petrotechPath()} />
      <Sete path={paths.setePath()} />
      <Aluminium path={paths.aluminiumPath()} />
      <Payment path={paths.paymentPath()} />
      <Contacts path={paths.contactsPath()} />
    </Router>
    <Match path={paths.rootPath()}>
      {() => <Footer />}
    </Match>
  </div>
);
