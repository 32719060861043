export const rootPath = () => '/';

export const aluminiumPath = () => `/aluminium/`;
export const paymentPath = () => `/payment/`;
export const contactsPath = () => `/contacts/`;

export const holdersPath = () => '/holders/';
export const roborexPath = () => `${holdersPath()}roborex/`;
export const vormannPath = () => `${holdersPath()}vormann/`;
export const petrotechPath = () => `${holdersPath()}petrotech/`;
export const setePath = () => `${holdersPath()}sete/`;
