import { h } from 'preact';
import { FontAwesomeIcon } from '@components/common';
import { Product } from '@components/product/Product';
import { contactsPath } from '@helpers/path-helpers';
import styles from './Sete.less';

const IMAGES_PATH = '/assets/media/holders/sete';

const IMAGES = {
  SETE: [
    {
      name: 'sete-holder',
      src: `${IMAGES_PATH}/sete-holder.jpeg`,
      alt: 'Кронштейны «SETE»',
    },
  ],
};

const PRICE_LIST_LINK = 'https://tinyurl.com/sete-holders';

const PRICE_LIST = {
  label: 'Цена:',
  rows: [
    <a
      className={styles.price_list}
      href={PRICE_LIST_LINK}
      target="_blank"
      rel="noreferrer"
    >
      Открыть прайс
      <FontAwesomeIcon icon="file-excel" />
    </a>,
  ],
  width: 165,
};

export const Sete = () => (
  <div className={styles.container}>
    <Product
      title={<span>Кронштейн складной <strong>«SETE»</strong> (Россия)</span>}
      images={IMAGES.SETE}
      description={
        <div>
          <p>
            Надежный кронштейн для настенного крепления складной полки, стола. С такими
            кронштейнами и столешницей у Вас получится отличный настенный стол, полка или сиденье
            в комнате, на балконе или в гараже, на даче.
          </p>
          <p>
            Нажимные клавиши в кронштейнах «SETE» расположены у самого края кронштейна. Это значит,
            что на готовом столе они будут находиться не дальше глубины вытянутой ладони от края
            столешницы. Такое расположение позволяет легко находить нажимные клавиши под
            столешницей и удобно складывать ее вниз. В похожих кронштейнах, где нажимная клавиша
            расположена ближе к середине кронштейна, при складывании столешницы вниз приходится
            приседать и, засунув руку под столешницу почти по локоть, искать нажимную клавишу
            для складывания столешницы.
          </p>
          <p>
            Чтобы разложить стол, нужно просто потянуть за край столешницы вверх. Когда столешница
            примет горизонтальное положение, стопор автоматически зафиксирует положение столешницы.
            Чтобы сложить кронштейн, нужно нажать на подпружиненную клавишу и разблокировать
            фиксатор. После этого столешница легко опустится до вертикального положения вдоль стены.
          </p>
          <p>
            Допустимая нагрузка на пару кронштейнов – 150 кг распределенного по столешнице веса.
            Складные кронштейны марки «SETE» представлены четырьмя моделями разных размеров
            – 250, 300, 350 и 400 мм. Вес пары кронштейнов – от 0,75 до 1,2 кг. Материал, из
            которого сделаны кронштейны «SETE» – нержавеющая сталь. Кронштейны продаются парой.
            Упакованы в индивидуальную картонную упаковку. Поставляются без крепежа.
            Крепежный комплект подбирается самостоятельно под конкретный материал стены.
          </p>
          <p>
            <img
              className={styles.other_img}
              style={{ maxWidth: 'none' }}
              src={`${IMAGES_PATH}/sete-holder-2.jpeg`}
              alt="Кронштейн «Sete»"
            />
            <img
              className={styles.other_img}
              style={{ maxWidth: 'none' }}
              src={`${IMAGES_PATH}/sete-holder-3.jpeg`}
              alt="Размерные обозначения кронштейна «Sete»"
            />
          </p>
        </div>
      }
      info={[
        {
          label: 'Макс. нагрузка:',
          rows: [
            <span>150 кг на пару</span>,
          ],
          width: 140,
        },
        {
          label: 'Размеры (AxBxC), мм.:',
          rows: [
            <span>250	x 115	x 26</span>,
            <span>300 x	117 x	26</span>,
            <span>350 x	117 x	26</span>,
            <span>400 x	117 x	26</span>,
          ],
          width: 190,
        },
        PRICE_LIST,
      ]}
    />
    <h4 style={{ textAlign: 'center', marginBottom: 30 }}>
      По стоимости и наличию обращайтесь в офис компании по адресам,
      указанным в разделе <a href={contactsPath()}>"Контакты"</a>
    </h4>
  </div>
);
