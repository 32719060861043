import { h } from 'preact';
import { contactsPath } from '@helpers/path-helpers';
import styles from './Payment.less';

const PAYCHECK_PATH = '/assets/media/paycheck.jpg';
const PAYCHECK_IMG_ALT = 'Образец платёжного документа АЛЮМАКС';

const EMAIL_PATH = 'mailto:info@alumaks.by';
const VIBER_PATH = 'viber://chat?number=%2B375296837963';
const WHATSAPP_PATH = 'https://api.whatsapp.com/send?phone=375296837963';

export const Payment = () => (
  <div className={styles.container}>
    <h1>Сделать заказ вы можете следующими способами:</h1>
    <p>
      <ul>
        <li>
          Позвонить в офис по городским или мобильным телефонам, указанным в разделе{' '}
          <a href={contactsPath()}>"Контакты"</a>;
        </li>
        <li>
          Отправить заявку на
          {' '}
          <a href={EMAIL_PATH} target="_blank" rel="noopener noreferrer">
            электронную почту
          </a>,
          {' '}
          <a href={VIBER_PATH} target="_blank" rel="noopener noreferrer">
            Viber
          </a> или
          {' '}
          <a href={WHATSAPP_PATH} target="_blank" rel="noopener noreferrer">
            WhatsApp
          </a>.
        </li>
      </ul>
      <br />Обработка заказов осуществляется по рабочим дням с 9:00 до 17:00.
    </p>
    <h1>Возможны следующие варианты оплаты и доставки:</h1>
    <p>
      <h2>Для юридических лиц:</h2>
      <p>
        Расчет производится безналичным платежом. Для этого при оформлении заявки Вам
        необходимо отправить нам ваши реквизиты для выставления счета. После поступления денежных
        средств Вы можете приехать за заказом, имея при себе доверенность или печать.
      </p>
    </p>
    <p>
      <h2>Для физических лиц:</h2>
      <p>
        Вы можете приехать к нам в офис для оплаты заказа (
        <strong>мы принимаем только наличные белорусские рубли!</strong>
        ), после чего на месте можно будет забрать заказ самовывозом.
        Также Вы можете запросить у нас счет-фактуру и произвести оплату в банке. После
        поступления денежных средств Вы можете приехать за заказом в наш офис.
      </p>
      <p>
        Кроме того, Вы можете заказать услугу доставки с оплатой наличными курьеру или с
        предварительной оплатой через банк. Доставка осуществляется курьерской службой
        ТК «Автолайтэкспресс». Стоимость доставки зависит от региона и веса посылки.
        Ориентировочная стоимость доставки по Минску — 8-11 руб., По РБ — 10-16 руб.
        Точную стоимость доставки можно узнать при оформлении заказа. Обращаем Ваше
        внимание, что если оплата за заказ производится курьеру при доставке товара
        (наложенный платеж), <strong>расчет возможен только наличными белорусскими
        рублями!</strong> Как правило, если заказ сделан в первой половине дня, доставка
        осуществляется на следующий рабочий день.
      </p>
    </p>
    <h1>Образец платёжного документа:</h1>
    <p>
      При оплате заказа в нашем офисе вам будет выдана квитанция следующего
      образца:
      <div className={styles.image}>
        <img src={PAYCHECK_PATH} alt={PAYCHECK_IMG_ALT} />
      </div>
    </p>
  </div>
);
