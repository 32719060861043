import { h } from 'preact';
import styles from './ContentBlock.less';

export const ContentBlock = ({ title, children }) => {
  return (
    <section className={styles.contentBlock}>
      <div className={styles.header}>
        <h2>{title}</h2>
      </div>
      <div className={styles.content}>
        {children}
      </div>
    </section>
  );
};
